<template> 
    <div style="background:#fff; margin-top:5px;margin-left:3px; "> 
        <div v-if="toolFlag" style="border-bottom:1px solid #ccc;border-left:2px solid #409eff;min-width:1100px; ">
            <span style="margin-left:5px;">字段1</span>
            <el-select v-model="selparam"  placeholder="请选择列名" clearable size="small" style="width:150px;margin-left:5px; ">
                <span v-for="item in tabCol" :key='item.key'>
                    <el-option v-if="item.fhide==='N'" :label="item.title" :value="item.key"></el-option>
                </span>
            </el-select>
            <el-select v-model="oper"  size="small" style="width:110px;margin-bottom:3px;margin-left:2px; ">
                <el-option label="等于" value="="></el-option>
                <el-option label="相似于" value="like"></el-option>
                <el-option label="大于等于" value="&ge;"></el-option>
                <el-option label="小于等于" value="&le;"></el-option>
            </el-select>
            <el-input placeholder="请输入查询值" @keyup.enter.native="qryTabData" v-model="textparam" size="small" style="width:230px;margin-left:2px;margin-top:3px; ">
                <el-button slot="append" icon="el-icon-search" @click="qryTabData" style="color:#409eff"></el-button>
            </el-input>
            <span style="margin-left:10px;">字段2</span>
            <el-select v-model="selparam2"  placeholder="请选择列名" clearable size="small" style="width:150px;margin-left:5px; ">
                <span v-for="item in tabCol" :key='item.key'>
                    <el-option v-if="item.fhide=='N'" :label="item.title" :value="item.key"></el-option>
                </span>
            </el-select>
            <el-select v-model="oper2"  size="small" style="width:110px;margin-bottom:3px;margin-left:2px; ">
                <el-option label="等于" value="="></el-option>
                <el-option label="相似于" value="like"></el-option>
                <el-option label="大于等于" value="&ge;"></el-option>
                <el-option label="小于等于" value="&le;"></el-option>
            </el-select>
            <el-input placeholder="请输入查询值" @keyup.enter.native="qryTabData" v-model="textparam2" size="small" style="width:230px;margin-left:2px;margin-top:3px; ">
                <el-button slot="append" icon="el-icon-search" @click="qryTabData" style="color:#409eff"></el-button>
            </el-input>    
        </div>
        <el-table :data="dtlData" 
                border                            
                disabled-hover
                style="overflow:auto;margin-top:3px;"
                v-loading="loading"
                element-loading-background = "rgba(255, 255, 255, .5)"
                element-loading-text = "加载中，请稍后..."
                element-loading-spinner = "el-icon-loading"
                size="small"
                ref="frmtab"
                :height="tabHeight" 
                :max-height="tabHeight" 
                :row-key="getRowKey"
                highlight-current-row>
            <span  v-for="(item ,index) in tabCol" :key="index">   
                <el-table-column   v-if="item.thide==='N'"
                    :prop="item.key"
                    :label="item.title"
                    show-overflow-tooltip
                    :align="item.align"  
                    :fixed="item.key==='RN'"                                
                    sortable
                    :min-width="item.width">
                </el-table-column> 
            </span> 
            <el-table-column
                fixed="right"
                label="操作"
                align="center"
                width="120">
                <template slot-scope="scope">
                    <i class="iconfont icon-chakan-copy" style="color:#409EFF;font-size:12px;margin-right:5px;"></i>
                    <el-link   @click="qryLog(scope.row )" :underline="false"  ><span style="font-size:11px;margin-right:15px;">查看日志 </span></el-link>
                </template>
            </el-table-column>              
        </el-table>
        <div style="margin-top: 3px; overflow: hidden;display:flex;width:100%;background:white">
            <div style="float: left;">
                <Page :total="dataCount" :page-size="rowSize" show-total :current="current" @on-change="changePage"></Page>
            </div>
            <div style="color:#606266;margin:7px 10px;font-size:12px;width:35px;">每页</div>
            <el-select v-model="rowSize" size="small" style="width:80px;"  @change="initPage">
                <el-option 
                    v-for="item in pageList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <div style="color:#606266;margin:7px 10px;margin-left:80px;font-size:12px;width:30px;">跳至</div>
            <el-input v-enter-number size="small" v-model="goPage" style="width:50px;"></el-input> 
            <span style="color:#606266;margin:7px 5px;font-size:12px;">页</span>
            <el-button @click="goNext" size="small" type="primary" style="width:50px;margin-left:15px;height:32px;">GO</el-button>
        </div> 
        <!-- 提示 -->
        <el-dialog :visible.sync="promptWin" width="450px" append-to-body :show-close="false">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">提示</span>
            </div> 
            <span>{{prompt}}</span>
            <span slot="footer"  >
                <el-button size="small" type="primary" @click="promptWin = false">确 定</el-button>
            </span>
        </el-dialog>
        <!--  物料日志明细 -->
        <el-dialog class="pub_dialog"  :visible.sync="logWin" width="1200px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">物料日志明细</span>
            </div>            
            <el-table :data="logData" 
                    border                            
                    disabled-hover
                    style="overflow:auto;margin-top:3px;"
                    size="small"
                    :height="logHeight" 
                    highlight-current-row>
                <span  v-for="(item ,index) in logCol" :key="index">   
                    <el-table-column   v-if="item.fhide==='N' &&item.key!=='FLAG'"
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"  
                        :fixed="item.key==='RN'"                                
                        sortable
                        :min-width="item.width">
                    </el-table-column> 
                    <el-table-column  v-if="item.key==='FLAG'"
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        :min-width="item.width">
                        <template width="60" slot-scope="scope" >
                            <i v-if="scope.row.FLAG==='Y'" class="iconfont icon-xuanze" style="color:green"></i>
                            <i v-if="scope.row.FLAG==='N'" class="iconfont icon-xuanze" style="color:red"></i>
                        </template>
                    </el-table-column>  
                </span>          
            </el-table>
            <div slot="footer" style="color:#F56C6C;font-weight:600;font-size:12px;">
                提示：标记为红色：表示单据引起库存减少，绿色：表示单据数量引起库存增加
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getTabColOrData,getBaseData } from '../../api/user' 
import {getDate} from '../../api/Select' 
export default {
    name: 'stk_logqry',
    props: {
        toolFlag:{
            type:Boolean,
            default:true
        },
        v_cond:{
            type:String,
        }
    },
    data () {
        return {
            logWin:false,
            selparam2:'STKNM',// select 绑定值
            oper2:'like',
            textparam2:'', // input 绑定值
            rowIdseq:'', // 
            prompt:'', // 
            promptWin:false,
            input_hold:'请输入内容... 按回车键以查询',
            selparam:'STKNM',// select 绑定值
            oper:'like',
            textparam:'', // input 绑定值
            tabCol:[] ,//表列集合
            logCol:[],
            logData:[] ,// 
            dtlData:[],
            dataCount:0,
            rowSize:20,
            goPage:'',
            current:1,
            baseData:[] ,//表数据
 
            orgData:[] ,//组织部门
            editrmk:'',//  
            rowLstseq:'' ,//
            disabled:false,
            defaultProps: {  //el-tree 默认字段属性
                children: 'children',
                id:'id',
                icon:'icon',
                parentid:'',
                label: 'label'
            },
            v_url:this.$store.state.queryUrl , //多记录查询 like模式       
            v_username :this.$cookies.get('v_username' ), //用户名称 
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            loading:false,// table加载bool
            type:'create',
            pageList: [ {
                value: 20,
                label: '20'
                }, {
                value: 30,
                label: '30'
                }, {
                value: 40,
                label: '40'
            }],
            stfgBool:true,
            formCol:{}, //表格单行记录 model
            allData:[],
            notNull:[],
            treeid:'0',
            addBool:false, //新增,为true时--禁止
            editBool:false, //编辑,为true时--禁止
            delBool:false, //删除 ,为true时--禁止
            cfmBool:false, //审核 ,为true时--禁止
            cancelBool:false, //作废 ,为true时--禁止 
            v_admin:this.$cookies.get('v_admin') ,//  
        }
    },
    created () {
        this.getTabCol() //得到 表列数组 
        if (this.toolFlag){
            this.getFormData('') //初始查询表数据   
        }else{
          this.getFormData(this.v_cond)  
        }
    },
    components: {
 
    },
    watch: {
        v_cond:function(){ //侦测变量值改变时 执行此方法
            this.getFormData(this.v_cond)  
        }   
    },
    mounted () {   
 
    } ,
    computed: {
        tabHeight() {
          return  window.innerHeight -180
        },
        logHeight() {
          return  window.innerHeight -160
        },
 
    },
    methods: { 
        qryLog(row){
            this.logWin =true
            this.getLogData(row.STKNO,row.MATNO) 
        },
        //跳至页面
        goNext(){
            if (this.goPage){
                this.changePage(this.goPage)
                this.current =Number(this.goPage)
            }else{
                this.promtWin=true
                this.prompt='请输入跳至页数'
            }
        },
        //初始化查询页条数
        initPage(){
            //产品明细
            this.dataCount =this.allData.length //总条数
            // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
            if (this.allData.length < this.rowSize){
                this.dtlData = this.allData          
            }else{
                this.dtlData =this.allData.slice(0,this.rowSize) //返回选定元素的子数组，不包含结尾元素
            }
        },
        //表格页面导航
        changePage(index){
            var _start = ( index - 1 ) * this.rowSize;
            var _end = index * this.rowSize;
            this.dtlData = this.allData.slice(_start,_end);
        }, 
        getRowKey(row){
            return row.PKSEQ
        },
 
        //查询表格数据
        qryTabData(){
            let cond_=''
            if (this.selparam){ //列1
                if (this.oper==='like'){
                    cond_=cond_+' and '+this.selparam+' like \'%' + this.textparam+'%\''
                }else{
                    cond_=cond_+' and '+this.selparam +this.oper+'\''+this.textparam+'\''
                }
            }
            if (this.selparam2){ //列1
                if (this.oper2==='like'){
                    cond_=cond_+' and '+this.selparam2+' like \'%' + this.textparam2+'%\''
                }else{
                    cond_=cond_+' and '+this.selparam2 +this.oper2+'\''+this.textparam2+'\''
                }
            }
            this.getFormData(cond_ )
        },
 
        //获取表单数据
        getFormData(cond){
            var vm=this
            this.loading =true
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'V_IC_STKLOG','','','','','','',cond).then((res) => {              
                vm.allData=res.data  
                vm.initPage() 
                vm.loading =false         
            });            
        },
        //日志记录
        getLogData(stkno,matno){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_STKLOG_INIT','','','stkno',stkno,'matno',matno ,' and stfg=\'Y\' order by icdttm').then((res) => {              
                this.logData=[]
                this.logData=res.data  

            })           
        },
        //获取表列头字段  v-enter-number
        getTabCol(){
            var vm=this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','39','fty','HEAD','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.tabCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        thide: res.data[k].THIDE,
                        fhide: res.data[k].FHIDE,
                        disabled: res.data[k].DISABLED,
                        align: res.data[k].align,
                        width: res.data[k].width,
                        edcss: res.data[k].EDCSS,
                    })
                }
            })
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','39','fty','HEAD2','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.logCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        thide: res.data[k].THIDE,
                        fhide: res.data[k].FHIDE,
                        disabled: res.data[k].DISABLED,
                        align: res.data[k].align,
                        width: res.data[k].width,
                        edcss: res.data[k].EDCSS,
                    })
                }
            })
        },
    }
}
 
</script>
<style scoped lang="less" >
    .selinput{
        width:340px;
        margin-left:10px; 
    }
    .colwidth{
        width:140px;
    }
    .colwidth2{
        width:642px;
    }
    .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 0 auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    } 
    .item-width  {
        width: 48%;
        color:#409EFF;
    }
</style>
<style lang="less">
    .el-tree-node.is-current > .el-tree-node__content {
      background-color: #f3b6b6 !important;
      color:rgb(30, 44, 90);
    }
    .el-tree-node__content:hover {    
      background-color: #66b1ff87;
    }
    .tree-scroll{
        width :180px;
        border: 1px solid #f5dbdb;
        height: 100%;
    }
   .el-table {
    th {
        padding: 0 ;
    }
    thead{
        height: 15px !important;
        color:rgb(128,128, 128);
        font-size:12px;
    }
    td {
        padding: 5px ;
        font-size:11px;
    }   
  }
  .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    .messageBox .el-message-box__content{
        border-top: 1px solid #EBEEF5;
        height: 50px;
    }
    .el-message-box__message p{
        line-height: 30px;
    }
    .messageBox .el-message-box__btns{
        padding: 20px 15px 0;
    }
    .messageBox .el-message-box__header{
        background:#409eff;
    }
    .messageBox .el-message-box__title{
        color:white ;
        font-size:14px;
    }
</style>
